import { notification, Typography, Upload } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { debounce } from 'lodash';

const { Dragger } = Upload;
const { Text } = Typography;

const Uploader = ({
  onFilesChange,
  data,
}: {
  onFilesChange: any;
  data: any;
}) => {
  const onChange = debounce((info: UploadChangeParam<any>) => {
    const files = info.fileList;
    const gpsPattern = /\d+[aA]_gps\.[^.]+$/;
    const speedPattern = /\d+[aA]_speed\.[^.]+$/;

    const validFiles = files.filter(file => {
      const fileName = file.name.toLowerCase();
      if (fileName.match(gpsPattern) || fileName.match(speedPattern)) {
        return true;
      } else {
        console.error(`Invalid file name: ${file.name}`);
        notification.error({
          message: `Invalid file name: ${file.name}`,
        });
        return false;
      }
    });

    onFilesChange(validFiles);
  }, 300); // Adjust debounce delay as needed

  const onRemove = (file: UploadFile<any>) => {
    const updatedData = data.filter((item: any) => item.uid !== file.uid);
    onFilesChange(updatedData);
  };

  return (
    <Dragger
      name="file"
      multiple={true}
      onChange={onChange}
      showUploadList={true}
      onRemove={onRemove}
      fileList={data}
      beforeUpload={() => false}
      maxCount={100}
    >
      <Text type="secondary">
        <ImportOutlined style={{ fontSize: '40px' }} />
      </Text>
      <div className="my-3"></div>
      <Text type="secondary">
        Click or drag file to this area to upload files
      </Text>
    </Dragger>
  );
};

export default Uploader;
