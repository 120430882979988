import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  PullRequestOutlined,
  SaveOutlined,
  TagsOutlined,
  BackwardOutlined,
  CloseOutlined,
  CommentOutlined,
  ExportOutlined,
  LoadingOutlined,
  EditOutlined,
  ProfileOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';

import { IPenalty } from '@services/penalty';
import useModal, { IModalPayload } from '@hooks/use-modal';
import { useExportPenalty } from '@hooks/use-export-penalty';
import useAuth from '@hooks/use-auth';

const ActionCell = ({
  data,
  processing,
}: {
  data: IPenalty;
  processing: boolean;
}) => {
  const [, openDetail] =
    useModal<IModalPayload<{ id: string }>>('penalty-detail');
  const [, updatePenalty] =
    useModal<IModalPayload<{ ids: string[]; type: string }>>('update-penalty');
  const [, openCarrierDetails] =
    useModal<IModalPayload<{ id: number }>>('carrier-details');
  const { _id, carrier_id } = data;
  const { isExporting, exportToCSV } = useExportPenalty();
  const { data: authInfo } = useAuth();
  const { flatPermissions = {} } = authInfo || {};

  const normalActions = [
    {
      permissions: ['edit'],
      statuses: ['BILLED'],
      icon: <FileExcelOutlined />,
      label: 'Undo Billing',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            ids: [_id],
            type: 'undo-billing',
          },
        });
      },
    },

    {
      permissions: ['edit', 'view'],
      icon: isExporting ? <LoadingOutlined /> : <ExportOutlined />,
      label: 'Export',
      onClick: () => {
        exportToCSV({ ids: [_id] });
      },
    },
    {
      icon: <InfoCircleOutlined />,
      permissions: ['edit', 'view'],
      label: 'Detail',
      onClick: () => {
        openDetail({
          isOpen: true,
          payload: {
            id: _id,
          },
        });
      },
    },

    {
      permissions: ['edit'],
      statuses: [
        'APPLIED',
        'NOT_APPLIED',
        'PENDING',
        'BILLABLE',
        'BILLED',
        'TO_REVIEW',
      ],
      icon: <EditOutlined />,
      label: 'Edit Penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            ids: [_id],
            type: 'edit',
          },
        });
      },
    },
    {
      permissions: ['edit'],
      icon: <ProfileOutlined />,
      label: 'View Carrier',
      onClick: () => {
        openCarrierDetails({
          isOpen: true,
          payload: {
            id: carrier_id as unknown as number,
          },
        });
      },
    },

    {
      permissions: ['edit'],
      statuses: ['BILLABLE'],
      icon: <FileTextOutlined />,
      label: 'Invoice penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'invoice',
            ids: [_id],
          },
        });
      },
    },
    {
      permissions: ['edit'],
      statuses: ['PENDING', 'TO_REVIEW'],
      icon: <PullRequestOutlined />,
      label: 'Request information',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'request-information',
            ids: [_id],
          },
        });
      },
    },
    {
      permissions: ['edit'],
      icon: <TagsOutlined />,
      label: 'Assign tag',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'assign-tag',
            ids: [_id],
          },
        });
      },
    },

    {
      permissions: ['edit'],
      icon: <BackwardOutlined />,
      label: 'Reset to pending',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'reset-penalty',
            ids: [_id],
          },
        });
      },
    },

    {
      icon: <CommentOutlined />,
      label: 'Add comment',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'comment',
            ids: [_id],
          },
        });
      },
    },
    {
      permissions: ['edit'],
      statuses: [
        'APPLIED',
        'NOT_APPLIED',
        'PENDING',
        'BILLABLE',
        'BILLED',
        'TO_REVIEW',
      ],
      icon: <SaveOutlined />,
      label: 'Archive',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'archive',
            ids: [_id],
          },
        });
      },
    },
    {
      permissions: ['edit'],
      statuses: [
        'APPLIED',
        'NOT_APPLIED',
        'PENDING',
        'BILLABLE',
        'ARCHIVED',
        'TO_REVIEW',
      ],
      icon: <DeleteOutlined />,
      label: 'Delete',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'delete',
            ids: [_id],
          },
        });
      },
    },
  ];

  const processingActions = [
    {
      icon: <InfoCircleOutlined />,
      permissions: ['edit', 'view'],
      label: 'Detail',
      onClick: () => {
        openDetail({
          isOpen: true,
          payload: {
            id: _id,
          },
        });
      },
    },
    {
      permissions: ['edit'],
      statuses: ['PENDING', 'NOT_APPLIED', 'TO_REVIEW'],
      icon: <CheckCircleOutlined />,
      label: 'Apply penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            ids: [_id],
            type: 'apply',
          },
        });
      },
    },
    {
      permissions: ['edit'],
      statuses: ['PENDING', 'APPLIED', 'TO_REVIEW'],
      icon: <CloseOutlined />,
      label: 'Remove penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'remove',
            ids: [_id],
          },
        });
      },
    },
  ];

  const actions = processing ? processingActions : normalActions;
  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlay={
        <Menu
          items={actions
            .filter(item => {
              const { permissions = [] } = item;
              return permissions.length
                ? permissions.some(role => {
                    return flatPermissions[role];
                  })
                : true;
            })
            .filter(({ statuses }) => {
              return !statuses || statuses?.indexOf(data.status || '') >= 0;
            })
            .map(item => {
              return {
                ...item,
                key: item.label,
              };
            })}
        />
      }
    >
      <Button type="text">
        <MoreOutlined style={{ fontSize: '20px' }} />
      </Button>
    </Dropdown>
  );
};

export default ActionCell;
