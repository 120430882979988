export interface IJustification {
  category?: PenaltyCategory;
  body?: string;
  attachments_url?: string[];
}

export type IStats = {
  _id: string;
  total_amount: number;
  entries_count: number;
  percentage?: number;
}[];

export interface IStatus {
  code: string;
  name: string;
  color: string;
}

export interface IPenaltyResult {
  _id: string;
  success: number;
  message: string;
  entity?: IPenalty;
  details: { message: string; column: string }[];
}

export interface IPenaltyFilter {
  week?: number;
  status?: string[];
  tags?: string[];
  has_comment?: boolean;
  has_attachment?: boolean;
  order_date_range?: string;
  gps_tracked?: boolean;
  line_code?: string;
  sennder_reference?: string;
  customer_reference?: string;
  carrier_name?: string;
  delay_type?: string[];
  truck_plate?: string;
  trailer_plate?: string;
}

export interface IPenaltyBase {
  _id: string;
  order_id?: string;
  sennder_reference?: string;
  customer_reference?: string;
  carrier_id?: string;
  carrier_name?: string;
  status?: string;
  line_code?: string;
  order_date?: string;
  week?: number;
  comments?: number;
  received_on?: string;
  delay_type?: string;
  gps_tracked?: boolean;
  created_at?: string;
  justified_at?: string;
  billed_at?: string;
  expiration_date?: string;
  competence?: string;
  initial_delay_minutes?: number;
  delay_minutes?: number;
  route_composition?: string;
  route_km?: number;
  tour_composition?: string;
  tour_km?: number;
  initial_penalty_percentage?: number;
  penalty_percentage?: number;
  sda_penalty_cost?: number;
  total_net_price?: number;
  total_net_cost?: number;
  planned_departure_at?: string;
  integration_request_body?: string;
  processing_category?: string;
  processing_body?: string;
  actual_departure_at?: {
    timestamp?: string;
    source?: string;
  };
  planned_arrival_at?: string;
  actual_arrival_at?: {
    timestamp?: string;
    source?: string;
  };
  justification?: IJustification;
  message?: string;
  invoice_number?: Number;
  truck_plate?: string;
  trailer_plate?: string;
  reviewed_at?: Date;
  review_time?: number;
  billed_amount?: Number;
  billed_undo_at?: Date;
  billed_undo_author?: String;
  billed_undo_invoice_number?: Number;
  internal_files?: [{ file_name: string; url: string; file_type: string }];
}

export interface IPenalty extends IPenaltyBase {
  tags?: string[];
  thread?: {
    id?: string;
    subscribers?: string[];
  };
  penalty_percentage: number;
}

export interface IPenaltyError {
  column: string;
  message: string;
}

export interface IPenaltyImport extends IPenaltyBase {
  tags?: string;
  thread?: {
    id?: string;
    subscribers?: string;
  };
  errors?: IPenaltyError[];
}

export interface INoShowPenaltyImport {
  week: number;
  order_id: number;
  sennder_reference: string;
  customer_reference: string;
  carrier_id: number;
  carrier_name: string;
  status: string;
  line_code: string;
  order_date: Date;
  received_on: Date;
  delay_type: 'no show';
  tour_composition: string;
  route_composition: string;
  initial_penalty_percentage: number;
  penalty_percentage: number;
  sda_penalty_cost?: number;
  total_net_cost: number;
  tags?: string;
  errors?: IPenaltyError[];
}

export interface INormalPenaltyImport {
  customer_reference: string;
  order_date: Date;
  line_code: string;
  competence: string;
  penalty_percentage: number;
  tour_composition: string;
  route_composition: string;
  planned_departure_at: Date;
  actual_departure_at: Date;
  planned_arrival_at: Date;
  actual_arrival_at: Date;
  delay_minutes: number;
  delay_type: string;
  errors?: IPenaltyError[];
}

export interface INoShowPenaltyImport {
  customer_reference: string;
  order_date: Date;
  line_code: string;
  competence: string;
  penalty_percentage: number;
  total_net_cost: number;
  total_net_price: number;
  tour_composition: string;
  route_composition: string;
  errors?: IPenaltyError[];
}

export type PenaltyCategory =
  | 'BROKEN_VEHICLE'
  | 'WRONG_DOCUMENT'
  | 'EMPTY_VEHICLE'
  | 'TRAVEL_TIME'
  | 'HUB_DELAY'
  | 'TRAFFIC'
  | 'FERRY_DELAY'
  | 'VEHICLE_ACCIDENT'
  | 'POLICE_STOP'
  | 'HUB_OPENING'
  | 'OTHER';

export enum PenaltyJustificationCategory {
  BROKEN_VEHICLE = 'Guasto mezzo',
  WRONG_DOCUMENT = 'Ritardo rilevato sul Documento di Viaggio non coerente',
  EMPTY_VEHICLE = 'Mezzo vuoto',
  TRAVEL_TIME = 'Tempi di percorrenza non rispettabili',
  HUB_DELAY = 'Ritardo causato da Hub/Filiale/Cliente',
  TRAFFIC = 'Traffico, cantieri stradali, condizioni metereologiche',
  FERRY_DELAY = 'Ritardo traghetto',
  VEHICLE_ACCIDENT = 'Incidente stradale al mezzo che esegue il servizio',
  POLICE_STOP = 'Fermo Polizia',
  HUB_OPENING = 'Orari aperture Hub/Filiali non compatibili con schedulati',
  OTHER = 'Altro',
}
