import { LinkOutlined } from '@ant-design/icons';
import { uploadInternalFile } from '@services/penalty';
import { notification, Progress } from 'antd';
import { useEffect, useState } from 'react';

const FileUpload = ({ data }: { data: any }) => {
  const [uploaderData, setUploaderData] = useState<any[]>(
    data
      .filter((file: any, index: number, self: any[]) => {
        return index === self.findIndex(f => f.name === file.name);
      })
      .map((file: any) => {
        return {
          name: file.name,
          percentage: 0,
          error: false,
          url: null,
          file,
        };
      }),
  );

  useEffect(() => {
    const processFiles = async () => {
      setUploaderData(prevData =>
        prevData.map(doc => ({
          ...doc,
          percentage: doc.percentage === 0 ? 50 : doc.percentage,
        })),
      );
      console.log(uploaderData);
      for (let doc of uploaderData) {
        const docSplit = doc.name.split('_');
        const sennderRef = docSplit[0];
        console.log(sennderRef);
        console.log(docSplit);
        const fileType = docSplit[1].toLowerCase().includes('gps')
          ? 'GPS'
          : 'SPEED_GRAPH';
        const file = doc.file;

        try {
          const res = await uploadInternalFile(sennderRef, fileType, file);
          setUploaderData(prevData =>
            prevData.map(item =>
              item.name === doc.name
                ? { ...item, percentage: 100, url: res.url }
                : item,
            ),
          );
        } catch (error: any) {
          notification.error({
            message: `${sennderRef}: ${error.response.data.error}`,
          });
          setUploaderData(prevData =>
            prevData.map(item =>
              item.name === doc.name
                ? { ...item, percentage: 70, error: true }
                : item,
            ),
          );
        }
      }
    };
    processFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {data.map((file: any) => {
        const uploadData = uploaderData.find((d: any) => d.name === file.name);
        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            {uploadData.url ? (
              <a href={uploadData.url} target="_blank" rel="noreferrer">
                {file.name} <LinkOutlined />
              </a>
            ) : (
              <p>{file.name}</p>
            )}
            {uploadData.error ? (
              <Progress percent={uploadData.percentage} status="exception" />
            ) : (
              <Progress percent={uploadData.percentage} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FileUpload;
