import ImagePreview from '@modules/ImagePreview';
import { Popover, Spin, Typography } from 'antd';
import {
  FileOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  MailOutlined,
  AudioOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { getAttachmentType } from '@utils/utilities';
import { useEffect, useState } from 'react';
import { getAttachment } from '@services/penalty';

const { Link } = Typography;

const JustificationAttachments = ({ list }: { list: string[] }) => {
  const [attachments, setAttachments] = useState<any>(null);
  const [isLoading, setLoading] = useState<any>(false);

  useEffect(() => {
    const fetchAttachment = async (url: string) => {
      try {
        const attachmentData = await getAttachment(url);
        return attachmentData;
      } catch (error) {
        return null;
      }
    };

    const fetchAllAttachments = async () => {
      setLoading(true);
      try {
        const results = await Promise.all(
          list.map(async item => {
            const downloadUrl = await fetchAttachment(item);
            return {
              sharepoint_url: item,
              download_url: downloadUrl.url,
              edit_url: downloadUrl.edit_url,
            };
          }),
        );
        setAttachments(results as any);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (list && list.length > 0) {
      fetchAllAttachments();
    }
  }, [list]);

  return (
    <Spin spinning={isLoading}>
      <div>
        {attachments &&
          attachments.map((attachment: any, index: number) => {
            const attachmentType = getAttachmentType(attachment.sharepoint_url);
            let fileIcon = <FileOutlined style={{ fontSize: '25px' }} />;

            switch (attachmentType) {
              case 'image':
                fileIcon = <FileImageOutlined style={{ fontSize: '25px' }} />;
                break;
              case 'pdf':
                fileIcon = <FilePdfOutlined style={{ fontSize: '25px' }} />;
                break;
              case 'doc':
                fileIcon = <FileExcelOutlined style={{ fontSize: '25px' }} />;
                break;
              case 'email':
                fileIcon = <MailOutlined style={{ fontSize: '25px' }} />;
                break;
              case 'audio':
                fileIcon = <AudioOutlined style={{ fontSize: '25px' }} />;
                break;
              case 'video':
                fileIcon = <VideoCameraOutlined style={{ fontSize: '25px' }} />;
                break;
              default:
                fileIcon = <FileOutlined style={{ fontSize: '25px' }} />;
                break;
            }
            return (
              <Popover
                placement={'top'}
                key={index}
                content={
                  <ImagePreview
                    attachmentType={attachmentType}
                    downloadUrl={attachment.download_url}
                    attachmentUrl={attachment.sharepoint_url}
                  />
                }
                title={'Attachment Preview'}
              >
                <Link
                  key={index}
                  href={attachment.edit_url || attachment.sharepoint_url}
                  target="_blank"
                >
                  {fileIcon}
                </Link>
              </Popover>
            );
          })}
      </div>
    </Spin>
  );
};

export default JustificationAttachments;
