import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Table, TableProps, Layout, notification } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useQuery } from 'react-query';

import {
  getPenalties,
  getStats,
  IPenalty,
  IPenaltyFilter,
  IStats,
} from '@services/penalty';
import { handleAPIError, IFetchQuery } from '@services/base';

import ActionCell from './components/ActionCell';
import ReferenceCell from './components/ReferenceCell';
import CellPenalty from './components/CellPenalty';
import CellStatus from './components/CellStatus';
import CellDelay from './components/CellDelay';
import CellWeek from './components/CellWeek';
import CellArc from './components/CellArc';
import CellJustification from './components/CellJustification';
import CellTag from './components/CellTag';
import CellPlate from './components/CellPlate';

import BulkActions from './components/BulkActions';
import GridFilter from './components/GridFilter';
import Stats from './components/Stats';
import { formatDate } from '@utils/utilities';
import { useExportPenalty } from '@hooks/use-export-penalty';
import CellBilledAmount from './components/CellBilledAmount';
import CellJustificationAttachments from './components/CellJustificationAttachments';
import CellInternalFiles from './components/CellInternalFiles';

const { Content } = Layout;

const Widget: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const start = new Date(currentYear, 0, 1);
  const end = new Date(currentYear, 11, 31);

  const [query, setQuery] = useState<IFetchQuery>({
    page: 1,
    page_size: 100,
  });
  const refContainer = useRef<HTMLDivElement>(null);
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const mappedRows = useMemo(
    () => selectedRows.map(id => id as string),
    [selectedRows],
  );

  const [processingIds, setProcessingIds] = useState<string[]>([]);
  const [processingTime, setProcessingTime] = useState(0);
  const processingIntervalRef = useRef<any>(null);
  const [processing, setProcessing] = useState(false);

  const [data, setData] = useState<IPenalty[]>([]);
  const [stats, setStats] = useState<IStats>();
  const [filter, setFilter] = useState<IPenaltyFilter>({
    order_date_range: `${start},${end}`,
  });
  const [pagination, setPagination] = useState<any>({
    current: 1,
    total: 0,
    pageSize: 100,
    showSizeChanger: false,
    position: ['bottomCenter'],
  });
  const { isExporting, exportToCSV } = useExportPenalty();

  const columns: ColumnsType<IPenalty> = [
    {
      title: 'Reference',
      render: ReferenceCell,
      key: 'customer_reference',
      sorter: (a, b) => {
        const value1 = a.customer_reference || '';
        const value2 = b.customer_reference || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 220,
      fixed: 'left',
    },

    {
      title: 'Carrier',
      key: 'carrier_name',
      dataIndex: 'carrier_name',
      sorter: (a, b) => {
        const value1 = a.carrier_name || '';
        const value2 = b.carrier_name || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 250,
    },
    {
      title: 'Plate',
      render: (data: IPenalty) => {
        return <CellPlate data={data} />;
      },
      key: 'plate',
      showSorterTooltip: false,
      width: 180,
      sorter: (a, b) => {
        const value1 = a.truck_plate || '';
        const value2 = b.truck_plate || '';
        return value1.localeCompare(value2);
      },
    },
    {
      title: 'Route',
      render: (data: IPenalty) => <CellArc data={data} />,
      key: 'route_composition',
      sorter: (a, b) => {
        const value1 = a.route_composition || '';
        const value2 = b.route_composition || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 140,
    },
    {
      title: 'Delay',
      render: (data: IPenalty) => {
        return <CellDelay data={data} />;
      },
      key: 'delay_minutes',
      showSorterTooltip: false,
      width: 100,
      sorter: (a, b) => {
        const time1 = a.delay_minutes || 0;
        const time2 = b.delay_minutes || 0;
        return time1 - time2;
      },
    },
    {
      title: 'Penalty',
      render: (data: IPenalty) => {
        return <CellPenalty data={data} />;
      },
      key: 'penalty_percentage',
      sorter: (a, b) => {
        const percent1 = a.penalty_percentage || 0;
        const percent2 = b.penalty_percentage || 0;
        return percent1 - percent2;
      },
      showSorterTooltip: false,
      width: 150,
    },

    {
      title: 'Billed Amount',
      key: 'billed_amount',
      render: (data: IPenalty) => {
        return <CellBilledAmount data={data} />;
      },
      sorter: (a, b) => {
        const percent1: any = a.billed_amount || 0;
        const percent2: any = b.billed_amount || 0;
        return percent1 - percent2;
      },
      showSorterTooltip: false,
      width: 150,
    },

    {
      title: 'Week',
      key: 'week',
      sorter: (a, b) => {
        const w1 = a.week || 0;
        const w2 = b.week || 0;
        return w1 - w2;
      },
      showSorterTooltip: false,
      width: 80,
      render: (data: IPenalty) => {
        return <CellWeek data={data} />;
      },
    },
    {
      title: 'Justification',
      render: (data: IPenalty) => <CellJustification data={data} />,
      width: 500,
    },
    {
      title: 'Attachments',
      render: (data: IPenalty) => <CellJustificationAttachments data={data} />,
      width: 200,
    },
    {
      title: 'Internal Files',
      render: (data: IPenalty) => <CellInternalFiles data={data} />,
      width: 200,
    },
    {
      title: 'Tags',
      render: (data: IPenalty) => <CellTag data={data} />,
      width: 200,
    },
    {
      title: 'Status',
      render: (data: IPenalty) => {
        return <CellStatus data={data} />;
      },
      key: 'status',
      sorter: (a, b) => {
        const value1 = a.status || '';
        const value2 = b.status || '';
        return value1.localeCompare(value2);
      },
      showSorterTooltip: false,
      width: 150,
    },
    {
      title: 'Order Date',
      render: (data: IPenalty) => {
        return <p>{formatDate(data.order_date)}</p>;
      },
      sorter: (a, b) => {
        const date1: any = a.order_date;
        const date2: any = b.order_date;
        return date1 - date2;
      },
      key: 'order_date',

      showSorterTooltip: false,
      width: 180,
    },
    {
      key: 'actions',
      width: 84,
      fixed: 'right',
      render: (data: IPenalty) => {
        return (
          <ActionCell
            data={data}
            processing={processingIds.includes(data._id)}
          />
        );
      },
    },
  ];

  const onSelectChange = (rows: React.Key[]) => {
    if (processingIds.length)
      return notification.error({
        message: 'Cannot select penalties while processing',
      });
    setSelectedRows(rows);
  };

  const { isFetching } = useQuery(
    ['penalties', filter, query],
    () => getPenalties({ ...query, ...filter }),
    {
      keepPreviousData: true,
      onSuccess: async res => {
        const penalties = res?.penalties || [];

        setPagination((prev: any) => ({
          ...prev,
          total: res?.total_values || 0,
        }));
        const stats = await getStats(filter);
        setStats(stats.status);
        setSelectedRows([]);

        setData(penalties);
      },
      onError: handleAPIError,
    },
  );

  const onChangeTable: TableProps<IPenalty>['onChange'] = async (
    pag,
    filters,
    sorters,
  ): Promise<void> => {
    let newQuery: IFetchQuery = {
      page: pag.current,
      page_size: 100,
    };

    const { order, columnKey } = sorters as SorterResult<IPenalty>;
    if (order) {
      newQuery = {
        ...newQuery,
        page_size: 100,
        order_by: columnKey,
        order_direction: order,
      };
    }
    if (pag.current !== pagination.current) {
      newQuery = { ...newQuery, page: pag.current, page_size: 100 };

      setPagination({
        ...pagination,
        current: pag.current,
        page_size: 100,
      });
    }
    setQuery(newQuery);
  };

  const onFilter = async (values: IPenaltyFilter) => {
    setQuery({ ...query, page: 1, page_size: 100 });
    setPagination({ ...pagination, current: 1 });
    setFilter(values);
  };

  const onCsvDownload = async () => {
    exportToCSV(filter);
  };

  const startProcessing = useCallback((ids: string[]) => {
    setProcessingIds(ids);
    if (processingIntervalRef.current !== null) return;

    processingIntervalRef.current = setInterval(() => {
      setProcessingTime(prevCount => prevCount + 1);
    }, 1000);
  }, []);

  const endProcessing = useCallback(() => {
    setProcessingIds([]);
    clearInterval(processingIntervalRef.current);
    processingIntervalRef.current = null;
    setProcessingTime(0);
  }, []);

  useEffect(() => {
    setProcessing(processingIds.length > 0);
  }, [processingIds]);

  return (
    <>
      <Stats isLoading={isFetching || isExporting} data={stats} />

      <Content
        className={'bg-white'}
        style={{ borderRadius: '15px', overflow: 'hidden' }}
      >
        <GridFilter
          data={filter}
          onFilter={onFilter}
          onCsvDownload={onCsvDownload}
        />
        <div ref={refContainer} style={{ height: '80%' }}>
          <Table
            rowSelection={{
              selectedRowKeys: selectedRows,
              onChange: onSelectChange,
            }}
            loading={isFetching || isExporting}
            onChange={onChangeTable}
            columns={columns}
            pagination={pagination}
            rowKey="_id"
            dataSource={data}
            scroll={
              refContainer?.current
                ? {
                    y: refContainer.current.clientHeight - 56 - 56,
                    x: refContainer.current.clientWidth,
                  }
                : undefined
            }
          />
          {selectedRows.length || processingIds.length ? (
            <BulkActions
              ids={mappedRows}
              processing={processing}
              processingTime={processingTime}
              onProcessingStart={startProcessing}
              onProcessingEnd={endProcessing}
            />
          ) : null}
        </div>
      </Content>
    </>
  );
};

export default Widget;
