import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import useAuth from '@hooks/use-auth';

import AppMenu from '@components/AppMenu';

import logo from '@assets/images/sennder-italia.png';
import styles from './styles.module.scss';

const { Header, Content } = Layout;

const AdminLayout = () => {
  let { isAuthenticating, data } = useAuth();
  let location = useLocation();

  if (isAuthenticating) {
    return null;
  }

  if (!data) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  const { email, photoURL, displayName } = data;

  return (
    <>
      <Header className={styles.header}>
        <div className={styles.avatar}>
          {photoURL ? (
            <img src={photoURL} alt={email} />
          ) : (
            displayName.charAt(0)
          )}
        </div>
        <div className={styles.logo}>
          <img src={logo} alt={'Sennder'} />
        </div>
        <AppMenu />
      </Header>
      <Content className={styles.content}>
        <Outlet />
      </Content>
    </>
  );
};

export default AdminLayout;
