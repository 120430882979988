import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AppProvider from '@components/AppProvider';
import AppLayout from '@components/AppLayout';
import AuthLayout from '@components/AuthLayout';
import AdminLayout from '@components/AdminLayout';

import PenaltiesAnalytics from '@modules/PenaltiesAnalytics';
import HomePage from '@modules/HomePage';
// import LoginPage from '@modules/LoginPage';
import LoginWithGooglePage from '@modules/LoginWithGooglePage';
import NotFoundPage from '@modules/NotFoundPage';

import '@styles/global.scss';
import '@styles/ant.less';
import AnalyticsLayout from '@components/AnalyticsLayout';

function App() {
  return (
    <AppProvider>
      <AppLayout>
        <Routes>
          <Route path="/auth" element={<AuthLayout />}>
            {/*<Route path="/auth/login" element={<LoginPage />} />*/}
            <Route path="/auth/login" element={<LoginWithGooglePage />} />
          </Route>
          <Route path="/" element={<AdminLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="/analytics" element={<AnalyticsLayout />}>
            <Route path="/analytics" element={<PenaltiesAnalytics />} />
          </Route>
        </Routes>
      </AppLayout>
    </AppProvider>
  );
}

export default App;
