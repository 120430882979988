import React from 'react';
import { Button, Space, Tooltip, Typography } from 'antd';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  FileTextOutlined,
  PullRequestOutlined,
  SaveOutlined,
  TagsOutlined,
  CloseOutlined,
  ExportOutlined,
  LoadingOutlined,
  EditOutlined,
  BackwardOutlined,
  SettingOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';

import useModal, { IModalPayload } from '@hooks/use-modal';
import classNames from 'classnames';
import { useExportPenalty } from '@hooks/use-export-penalty';
import useAuth from '@hooks/use-auth';

const { Text } = Typography;

const BulkActions = ({
  ids,
  onProcessingStart,
  onProcessingEnd,
  processing,
  processingTime,
}: {
  ids: string[];
  onProcessingStart: any;
  onProcessingEnd: any;
  processing: boolean;
  processingTime: number;
}) => {
  const [, updatePenalty] =
    useModal<IModalPayload<{ ids: string[]; type: string; time?: number }>>(
      'update-penalty',
    );
  const { isExporting, exportToCSV } = useExportPenalty();
  const { data } = useAuth();
  const { flatPermissions = {} } = data || {};
  const processingActions = [
    {
      colorType: 'danger',
      permissions: ['edit'],
      icon: <SettingOutlined />,
      label: 'Stop Processing',
      onClick: () => {
        onProcessingEnd();
      },
    },

    {
      colorType: 'default',
      permissions: ['edit'],
      icon: <CheckCircleOutlined />,
      label: 'Apply penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'apply',
            ids,
            time: processingTime,
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['edit'],
      icon: <CloseOutlined />,
      label: 'Remove penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'remove',
            ids,
            time: processingTime,
          },
        });
      },
    },
  ];
  const normalActions = [
    {
      colorType: 'primary',

      permissions: ['edit'],
      icon: <SettingOutlined />,
      label: 'Start processing',
      onClick: () => {
        onProcessingStart(ids);
      },
    },
    {
      colorType: 'default',

      permissions: ['edit', 'view'],
      icon: isExporting ? <LoadingOutlined /> : <ExportOutlined />,
      label: 'Export',
      onClick: () => {
        exportToCSV({ ids });
      },
    },

    {
      colorType: 'default',

      permissions: ['edit'],
      icon: <EditOutlined />,
      label: 'Edit Penalty',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            ids,
            type: 'edit',
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['edit'],
      icon: <FileExcelOutlined />,
      label: 'Undo billing',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'undo-billing',
            ids,
          },
        });
      },
    },

    {
      colorType: 'default',

      permissions: ['edit'],
      icon: <FileTextOutlined />,
      label: 'Invoice penalties',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'invoice',
            ids,
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['edit'],
      icon: <PullRequestOutlined />,
      label: 'Request information',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'request-information',
            ids,
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['edit'],
      icon: <TagsOutlined />,
      label: 'Assign tag',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'assign-tag',
            ids,
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['edit'],
      icon: <BackwardOutlined />,
      label: 'Reset to pending',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'reset-penalty',
            ids,
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['edit'],
      icon: <SaveOutlined />,
      label: 'Archive',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'archive',
            ids,
          },
        });
      },
    },
    {
      colorType: 'default',

      permissions: ['edit'],
      icon: <DeleteOutlined />,
      label: 'Delete',
      onClick: () => {
        updatePenalty({
          isOpen: true,
          payload: {
            type: 'delete',
            ids,
          },
        });
      },
    },
  ];
  const actions = processing ? processingActions : normalActions;

  const getActionItem = (item: any) => {
    const { permissions = [] } = item;
    const hasPermissions = permissions.length
      ? permissions.some((role: any) => {
          return flatPermissions[role];
        })
      : true;

    if (hasPermissions) {
      return (
        <Tooltip key={item.label} title={item.label}>
          <Button onClick={item.onClick} type={item.colorType}>
            {item.icon}
          </Button>
        </Tooltip>
      );
    }
    return null;
  };
  const actionsItems = actions.map(item => getActionItem(item));

  return (
    <div
      className={classNames('bulk-actions', {
        'bulk-actions--active': true,
      })}
    >
      <Space>
        <Text>
          {processing ? (
            <Text>
              Processing <strong className="mr-1">{ids.length}</strong>{' '}
              penalties
            </Text>
          ) : (
            <Text>
              <strong className="mr-1">{ids.length}</strong> selected items
            </Text>
          )}
        </Text>
        {actionsItems}
      </Space>
    </div>
  );
};

export default BulkActions;
